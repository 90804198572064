import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import { Button, Center } from '@chakra-ui/react';
import Link from '@interness/web-core/src/components/elements/Link/Link';
import Checkboard from '@interness/theme-sonora/src/components/Checkboard';
import Lightbox from '@interness/web-core/src/components/media/Lightbox/Lightbox';
import { GrGallery } from "react-icons/gr";
import { MdConstruction } from "react-icons/md";
export const query = graphql`
  query {
    gallery: directusMediaCollection(name: {eq: "galerie-schlafzimmer"}) {
        ...LightboxGalleryGrid
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Heading = makeShortcode("Heading");
const Wrapper = makeShortcode("Wrapper");
const InfoBlocks = makeShortcode("InfoBlocks");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Heading icon={<MdConstruction mdxType="MdConstruction" />} mdxType="Heading">Schlafzimmer</Heading>
    <Checkboard image={props.data.gallery.images[0].file.localFile} mdxType="Checkboard">
      <p>{`  Kleiderschränke für das Schlafzimmer und Stauraum-Lösungen vom Schrankstudio Schega: Das perfekte Angebot für eine
Maßangefertigte Lösung für ihre Räumlichkeiten. Egal wie tief, breit, hoch oder schmal.`}</p>
      <p>{`  Sie haben einen Platz für ihren Schrank im Raum, welcher besondere Maße hat und Sie Ihn deswegen nicht optimal nutzen
können? Wir haben für Sie garantiert die perfekte und passende Lösung, sodass Sie mit Ihrem neuen Stauraumwunder keine
Platzängste mehr haben müssen!`}</p>
      <p>{`  Die Schränke sind nach Wunsch in vielen Aspekten für Sie anpassbar: Größe, Tiefe, Maße, Farbe, Glas oder Holz, Dekor,
Schräge etc.- Preiswerter als in ihrem lokalen Möbelhaus.`}</p>
    </Checkboard>
    <hr></hr>
    <Center mdxType="Center">
  <Button variant="outline" colorScheme="brand" backgroundColor="white" sx={{
        textDecoration: 'none'
      }} as={Link} to="/kontakt" mdxType="Button">Zum
            kostenfreien Beratungstermin</Button>
    </Center>
    <hr></hr>
    <Heading icon={<GrGallery mdxType="GrGallery" />} tag='h2' mdxType="Heading">Galerie</Heading>
    <Lightbox thumbnailWidth={'350px'} images={props.data.gallery.images} thumbnails={props.data.gallery.thumbnails} fromApi mdxType="Lightbox" />
    <hr></hr>
    <Wrapper mdxType="Wrapper">
  <InfoBlocks mdxType="InfoBlocks" />
    </Wrapper>
    <hr></hr>
    <Center mdxType="Center">
  <Button variant="outline" colorScheme="brand" backgroundColor="white" sx={{
        textDecoration: 'none'
      }} as={Link} to="/kontakt" mdxType="Button">Zum
            kostenfreien Beratungstermin</Button>
    </Center>
    <hr></hr>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      